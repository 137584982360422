@font-face {
  font-family: "Exo2";
  src: url('../assets/fonts/exo_2/Exo2-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: "Exo2-Regular";
  src: url('../assets/fonts/exo_2/static/Exo2-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Exo2-Black";
  src: url('../assets/fonts//exo_2/static/Exo2-Black.ttf') format('truetype');
}

@font-face {
  font-family: "Exo2-Medium";
  src: url('../assets/fonts//exo_2/static/Exo2-Medium.ttf') format('truetype');
}