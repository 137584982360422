// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Include functions first
@import "bootstrap/scss/functions";

// Customize some defaults
$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #7952b3;

// Required
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
// @import "bootstrap/scss/placeholders";

// Helpers
// @import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

//
// Custom styles
//

@import "icon-list";
@import "font-list";

body {
  // font-family: "Exo2-Regular", Tahoma, sans-serif;
  font-family: "Exo2", 'Times New Roman';
  color: #505F98;
}

.btn {
  padding: 0.5em 3em;
  font-size: 12px;
  border: none;
  border-radius: 3px;

  &:hover {
    background-color: #f9b339;
  }
}

.btn-success {
  background-color: #111B47;
  color: #fff;
}

.btn-outline-success {
  color: #091133;
  border: 1px solid #091133;
}

a {
  color: #505F98;

  &:hover {
    text-decoration: underline;
  }
}

.hidden {
  visibility: hidden;
}

.reverse-background {
  background-color: #F3F5FF;
}

.offcanvas.show a.btn.btn-success {
  margin-left: 2.5em;
}

.offcanvas.show .navbar-nav {
  margin-top: 2em;
}

.close-menu-button {
  display: none;
}

.offcanvas.show .close-menu-button {
  display: inline;
  margin-left: 29px;
}


#navbarNav {
  .nav-link {
    padding: 0 2em 0em 2em;
    color: #484c5d;
    font-weight: 600;
  }
}

.logo {
  max-width: 600px;
  width: 100%;
}

p.phone-number {
  margin-bottom: 0;

  a {
    text-decoration: none;
    color: #091133;
    font-size: .8em;
  }
}

.headers {
  color: #37447E;
  font-family: 'Exo2-Black', Tahoma, sans-serif;
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 30px;
  font-family: 'Exo2-Medium', Tahoma, sans-serif;
  color: #091133;
}

p {
  font-size: 18px;
}

.form-control {
  border-radius: 0;
  background-color: #F3F5FF;
  color: #6F7CB2;
  border-color: #6F7CB2;
}

input::placeholder,
textarea::placeholder {
  color: #6F7CB2 !important;
  opacity: 1;
  /* Firefox */
}

.main-hero {  
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}



.banner {
  background-color: #F3F5FF;
  padding-bottom: 2em;
}


#rebate {
  text-align: center;
}

#partners .col-lg-3 {
  text-align: center;
}

.partner-logo {
  max-width: 50%;
}

.page-hero {
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}



.shape {
  display: block;
  background-color: #fff;
  width: 100%;
  height: 90px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.footer {
  background-color: #E7ECFF;
  color: #939EA4;

  nav {
    .container-fluid {
      padding: 0;
    }
  }

  .nav-link,
  a {
    color: #939EA4;

    &:hover {
      color: #111B47;
    }
  }

  .social-links a {
    padding-right: 10px;
  }
}


@include media-breakpoint-up(lg) {
  .banner {
    background-color: #fff;
    background-image: url(/assets/images/banner.svg);
    min-height: 800px;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;
    background-size: contain;
  }
  #rebate {
    text-align: initial;
  }

  .partner-logo {
    max-width: 200px;
  }
 
}